/* Defaults */

.page-content * img {
	max-width: 100%;
}

.ios .theme-dark .panel {
	background: black;
}

.sa-sidebar-left > div {
	overflow: scroll;
}

/* Discount card */

// Discount card card
.discount-card {
  height: 180px;
  width: 90%;
  max-width: 340px;
  margin-left: auto !important;
  margin-right: auto !important;
  border-radius: 10px;
  border: 1px solid #70707019;
  box-shadow: 0px 3px 6px #00000029;
  background: transparent linear-gradient(180deg, #F8F8F8 0%, #E6E6E6 100%) 0% 0% no-repeat padding-box;
  margin-bottom: 5vh;
}

.discount-card .card-content {
  height: -webkit-fill-available;
}

.discount-card .discount-images,
.discount-card .discount-username {
  height: 30px;
}

.discount-card .discount-images .pp-icon {
  height: auto !important;
}

.discount-card .discount-number {
  font-size: 28px;
  font-weight: 300;
  height: calc(120px - (var(--f7-card-content-padding-vertical) * 2));
  align-items: center;
}

.discount-card .discount-username {
  font-size: 16px;
  font-weight: 400;
  align-items: flex-end;
}

.rewards-container {
  padding-bottom: 2vh;
}

.rewards-container .item-input .item-inner  {
  padding: 0;
}

.rewards-container .purple-btn {
  width: max-content;
  padding: 0 20px;
}

.rewards-container .bw-btn {
  width: min-content;
  white-space: normal;
  padding: 5px 15px;
  margin-left: auto;
  line-height: unset;
  font-size: 12px;
  border-color: var(--purple);
}

.rewards-container .progressbar {
  margin-top: 1vh;
  border: 1px solid var(--light-purple);
  height: 15px;
}

.rewards-container .progressbar > span {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

/* Vendor profile */

// Black & white button
/*.vendor-profile-container .bw-btn {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: auto;
  width: 100px;
  height: 60px;
  white-space: normal;
  line-height: 24px;
  border-color: var(--purple);
}*/

// Status container
.status-col {
  display: flex;
  align-items: center;
}

.status-col .status-undefined,
.status-col .status-closed,
.status-col .status-open {
  border-radius: 25px;
  height: 17px;
  width: 17px;
  display: inline-block;
  margin-right: 10px;
}

.vendor-status span.status-closed,
.vendor-status span.status-open {
  border-radius: 25px;
  height: 17px;
  width: 17px;
  display: inline-block;
}

.status-col .status-undefined {
  background-color: var(--dark-grey);
}

.status-col .status-closed,
.vendor-status span.status-closed {
  background-color: var(--red);
}

.status-col .status-open,
.vendor-status span.status-open {
  background-color: var(--green);
}

// List container
.vendor-list-container .list .media-item:not(.address-item) .item-title-row {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vendor-list-container .item-media i.icon {
  color: var(--purple);
}

.vendor-list-container .link-item .pp-icon {
  display: block !important;
  fill: var(--purple);
}

.vendor-list-container .media-item .item-title {
  font-weight: 500;
  font-size: 16px;
}

.vendor-list-container .media-item.link-item .item-title {
  text-decoration: underline;
  cursor: pointer;
}

.vendor-list-container .address-item .item-title {
  white-space: break-spaces;
}

.vendor-list-container .media-list,
.vendor-list-container li.media-item {
  --f7-list-item-padding-vertical: 0;
}

.vendor-list-container .item-content {
  padding-left: 0;
  min-height: fit-content;
}

.vendor-list-container .media-item .item-inner {
  display: flex;
  min-height: fit-content;
  height: 35px;
}

.vendor-list-container .media-list .item-media {
  align-self: center;
}

// Buttons container
.vendor-buttons-container .buttons-row-1 {
  justify-content: flex-start;
}

.vendor-buttons-container .buttons-row-1 .col {
  padding-top: 2vh;
  margin: 0 10px;
  width: auto;
}

.external-btn,
.order-btn {
  font-size: 14px;
  font-weight: 500;
  text-transform: unset;
  height: 35px;
  width: fit-content;
  line-height: 35px;
}

.external-btn {
  background-color: var(--purple) !important;
  color: white !important;
}

.order-btn {
  border: 1px solid var(--light-purple) !important;
  color: var(--light-purple) !important;
  line-height: 33px;
}

// Features container
.vendor-features-container {
  margin-top: -0.5em;
  margin-bottom: -0.5em;
  padding-top: 1vh;
  padding-bottom: 1vh;
  background-color: #F6F6F6;
}

.vendor-features-container .row:last-of-type {
  width: auto;
  justify-content: flex-start;
}

.vendor-features-container .row:last-of-type .col {
  width: fit-content;
  margin-right: 10px;
  padding-bottom: 2vh;
}

// Loyalty container
.vendor-loyalty-container .loyalty-row-1,
.vendor-loyalty-container .loyalty-row-2 {
  padding-top: 2vh;
}

.reward-header-row p {
  font-size: 16px;
  font-weight: 600;
  color: var(--purple);
  margin: 0 0 10px;
}

.reward-desc-row p {
  font-size: 16px;
  font-weight: 500;
  color: var(--black);
  margin: 0 0 10px;
}

/* Filter */

// Filter container
.filter-container {
  padding: 2vh 0;
  text-align: center;
}

.filter-container > h1 {
  font-weight: 500;
  padding-bottom: 2vh;
  margin-top: 0;
}

/* Day buttons (opening hours & recurrence) */

.day-btns {
  text-align: -webkit-center;
}

.day-btns .col {
  width: 42px;
}

.day-btn {
  text-transform: none;
  height: 42px;
  width: 42px;
  line-height: 40px;
  font-size: 12px;
  text-overflow: unset;
  text-align: center;
  padding: 0;
}

.day-btn:not(.selected) {
  border: 1px solid var(--black);
  color: var(--black);
}

.day-btn.selected {
  border: 1px solid var(--purple);
  background-color: var(--purple);
  color: var(--orange);
}

.gmap-container .gm-style-iw-ch {
  display: none !important;
}
