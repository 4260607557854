<template>
  <f7-page
    class="page-template SATemplate-page-1775"
    :ptr="false"
    :no-swipeback="true"
    @page:afterin="openSheet"
    @page:beforeout="closeSheet"
    @page:afterout="resetVendor"
  >
    <template slot="fixed">
      <sac-c-pp-customheader title=" " :initial-post-id="1775" :on-back="backHandler" />
    </template>

    <template slot="default">
      <sac-c-pp-showprofilepicture v-if="Object.keys(vendor).length > 0 && vendor.logo" :size="82" :imageurl="vendor.logo" elemclass="vendor-logo" />
      <sac-c-pp-postslides
        v-if="Object.keys(vendor).length > 0"
        :contents="vendor.content"
        :content-id="contentId"
        :activity="userActivity"
        :place-id="vendor.place_id"
        :vendor-id="vendor.vendor_id"
        :vendor-name="vendor.company.public_name"
        :vendor-slug="vendor.vendor_name"
        :show-activity-buttons="true"
        :not-feed="false"
        :get-gimages="vendorClaimed"
        :has-map="hasMap"
        @updatevendor="updateVendor()"
      />
      <div v-if="Object.keys(vendor).length > 0" class="show-all-imgs" @click="currentUser ? navigate('/vendor-profile-2/images/', { vendor_id: vendorId, place_id: vendor.place_id }) : navigate('/vendor-profile-2/images-2/', { vendor_id: vendorId, place_id: vendor.place_id })">
        <f7-icon color="white" size="20" f7="rectangle_on_rectangle_angled" style="margin-right: 7px;" />
        <span>{{localeTexts.see_all_1}}<br>{{vendor.content.length}} {{localeTexts.see_all_2}}</span>
      </div>
      <f7-sheet
        v-if="Object.keys(vendor).length > 0 && !sheetDestroyed"
        ref="sheet"
        class="vendor-sheet page-template SATemplate-page-1775"
        style="--f7-sheet-bg-color: #fff"
        :backdrop="false"
        @sheet:stepprogress="onSheetProgress"
        swipe-to-step
        swipe-handler=".sheet-modal-swipe-step"
      >
        <div class="sheet-modal-swipe-step" style="background-color: #F6F6F6; text-align: center;" @click="expandOrCollapseSheet()">
          <f7-icon v-if="!sheetExpanded" id="view-profile-icon" size="35" f7="chevron_up" />
          <f7-icon v-if="sheetExpanded" size="35" f7="chevron_down" />
          <p v-if="!sheetExpanded">{{localeTexts.view_profile}}<br>&nbsp;</p>
          <!--<p v-if="sheetExpanded">Close</p>-->
          <hr>
        </div>

        <div class="vendor-sheet-content">
          <div class="vendor-profile-container web-width">
            <f7-row no-gap>
              <f7-col style="width: calc(100% - 165px);">
                <h1 class="margin-0">{{vendorName}}<f7-badge v-if="isEvent && eventContent.sold_out" class="sold-out-badge" color="red">{{localeTexts.sold_out}}</f7-badge></h1>
                <h2 v-if="isEvent" class="margin-0">{{eventContent.event_start_formated}}</h2>
                <h2 v-if="vendorDistance" class="margin-0">{{vendorDistance}} {{localeTexts.away_text}}</h2>
              </f7-col>
              <f7-col
                style="width: 165px; text-align: -webkit-right;">
                <f7-button
                  v-if="userFollowed === false"
                  :disabled="incognitoUser"
                  outline
                  class="follow-btn"
                  icon-f7="plus"
                  @click="updateFollow('add')"
                >{{localeTexts.follow_profile_btn}}</f7-button>
                <f7-button
                  v-else
                  :disabled="incognitoUser"
                  outline
                  class="unfollow-btn"
                  icon-f7="plus"
                  @click="updateFollow('delete')"
                >{{localeTexts.unfollow_profile_btn}}</f7-button>
              </f7-col>
            </f7-row>
            <div v-if="(vendor.opening_hours && vendor.opening_hours.length > 0) || (currentStatus.status != 'undefined')" style="margin: 2vh 0;">
              <f7-row v-if="vendor.opening_hours && vendor.opening_hours.length > 0" no-gap style="align-items: center;">
                <f7-col width="55" class="status-col underline-col" @click="showDialog('opening-popup')" :style="(currentStatus.status != 'undefined' ? 'margin-bottom: 1vh;' : '')">
                  <span :class="'status-' + openingInfo.status" /><h2 class="margin-0">{{openingInfo.hours}}</h2>
                </f7-col>
              </f7-row>
              <f7-row v-if="currentStatus.status != 'undefined'" no-gap style="align-items: center;">
                <f7-col v-if="vendor.status_text != ''" class="opening-col underline-col" width="55" @click="showDialog('custom-status-popup')">
                  <h2 class="margin-0" :style="(currentStatus.status != 'undefined' ? 'margin-bottom: 1vh;' : '')"><f7-icon size="19" f7="clock" />{{vendor.status.label}}</h2>
                </f7-col>
                <f7-col v-else class="opening-col" width="55">
                  <h2 class="margin-0" :style="(currentStatus.status != 'undefined' ? 'margin-bottom: 1vh;' : '')"><f7-icon size="19" f7="clock" />{{vendor.status.label}}</h2>
                </f7-col>
              </f7-row>
            </div>
          </div>
          <div v-if="vendor.bio" class="vendor-bio-container web-width">
            <p class="vendor-bio">{{vendor.bio}}</p>
          </div>
          <div class="vendor-list-container web-width">
            <f7-list media-list no-hairlines class="margin-0">
              <f7-list-item
                v-if="rating > 0"
              >
                <div slot="media" style="height: 20px;"><sac-c-pp-icon :size="20" icon="google" /></div>
                <div slot="title"><f7-icon size="20" f7="star_fill" style="color: #FFC23B; vertical-align: text-bottom;" /> {{rating}} {{localeTexts.rating_text}}</div>
              </f7-list-item>
              <f7-list-item
                :title="vendor.total_activity.likes == 1 ? vendor.total_activity.likes + ' ' + localeTexts.person_recommended : vendor.total_activity.likes + ' ' + localeTexts.people_recommended"
              >
                <div slot="media"><f7-icon size="20" f7="heart" /></div>
              </f7-list-item>
              <f7-list-item
                :title="vendor.total_activity.views == 1 ? vendor.total_activity.views + ' ' + localeTexts.person_viewed : vendor.total_activity.views + ' ' + localeTexts.people_viewed"
              >
                <div slot="media"><f7-icon size="20" f7="search" /></div>
              </f7-list-item>
              <f7-list-item
                :title="vendor.total_activity.followers == 1 ? vendor.total_activity.followers + ' ' + localeTexts.follower : vendor.total_activity.followers + ' ' + localeTexts.followers"
              >
                <div slot="media"><f7-icon size="20" f7="plus" /></div>
              </f7-list-item>
              <f7-list-item
                v-if="isEvent && eventContent.ticket_url"
                class="link-item"
              >
                <div slot="title" @click="openLink(eventContent.ticket_url)">{{localeTexts.buy_tickets}}</div>
                <div slot="media"><f7-icon size="20" f7="tickets" /></div>
              </f7-list-item>
              <f7-list-item
                v-if="isEvent && eventContent.reservation_url"
                class="link-item"
              >
                <div slot="title" @click="openLink(eventContent.reservation_url)">{{localeTexts.make_reservation}}</div>
                <div slot="media"><f7-icon size="20" f7="bookmark" /></div>
              </f7-list-item>
              <f7-list-item
                v-if="vendor.contact && vendor.contact.website"
                class="link-item"
              >
                <div slot="title" @click="openLink(vendor.contact.website)">{{vendor.contact.website.replace(/^https?:\/\//i, '')}}</div>
                <div slot="media"><f7-icon size="20" f7="globe" /></div>
              </f7-list-item>
              <f7-list-item
                v-if="vendor.contact && vendor.contact.ig_account"
                class="link-item"
              >
                <div slot="title" @click="openSocial('instagram')">{{vendor.contact.ig_account}}</div>
                <div slot="media"><img class="pp-icon" height="20" src="https://purplepages.app/wp-content/uploads/2024/01/instagram.svg"></div>
              </f7-list-item>
              <f7-list-item
                v-if="vendor.contact && vendor.contact.fb_account"
                class="link-item"
              >
                <div slot="title" @click="openSocial('facebook')">{{vendor.contact.fb_account}}</div>
                <div slot="media"><img class="pp-icon" height="20" src="https://purplepages.app/wp-content/uploads/2024/01/facebook.svg"></div>
              </f7-list-item>
              <f7-list-item
                v-if="vendor.contact && vendor.contact.tiktok_account"
                class="link-item"
              >
                <div slot="title" @click="openSocial('tiktok')">{{vendor.contact.tiktok_account}}</div>
                <div slot="media"><img class="pp-icon" height="20" src="https://purplepages.app/wp-content/uploads/2024/01/tiktok.svg"></div>
              </f7-list-item>
              <f7-list-item
                v-if="vendor.contact && vendor.contact.email"
                class="link-item"
              >
                <div slot="title" @click="contactLink('mailto:' + vendor.contact.email)">{{vendor.contact.email}}</div>
                <div slot="media"><f7-icon size="20" f7="at" /></div>
              </f7-list-item>
              <f7-list-item
                v-if="vendor.contact && vendor.contact.phone"
                class="link-item"
              >
                <div slot="title" @click="contactLink('tel:' + vendor.contact.phone)">{{vendor.contact.phone}}</div>
                <div slot="media"><f7-icon size="20" f7="device_phone_portrait" /></div>
              </f7-list-item>
              <f7-list-item
                v-if="vendor.location"
                class="address-item link-item"
              >
                <div slot="title" @click="openMap()">{{vendorAddress}}</div>
                <div slot="media" style="width: 20px; text-align: -webkit-center;"><img height="20" src="https://purplepages.app/wp-content/uploads/2023/05/PP-location.svg"></div>
              </f7-list-item>
            </f7-list>
          </div>
          <div v-if="vendor.custom_buttons && vendor.custom_buttons.length > 0" class="vendor-buttons-container web-width">
            <f7-row class="buttons-row-1" style="text-align: -webkit-center;">
              <f7-col
                v-for="(custom_btn, index) in vendor.custom_buttons"
                :key="index">
                <f7-button
                  class="external-btn"
                  @click="openLink(custom_btn.button_url)"
                >{{custom_btn.button_label}}</f7-button>
              </f7-col>
            </f7-row>
          </div>
          <hr>
          <div v-if="vendor.categories.length > 0" class="vendor-features-container">
            <h1 class="margin-0 web-width">{{localeTexts.restaurant_cats_title}}</h1>
            <f7-row class="web-width">
              <f7-col
                v-for="category in vendor.categories"
                :key="category.term_id"
                :id="'choice-col-' + category.term_id"
              >
                <f7-button
                  :id="'choice-' + category.term_id"
                  class="select-btn selected"
                  outline
                  round
                  small
                >
                  <span v-if="category.emoji">{{decodeHTMLEntities(category.name)}}</span>
                  <span v-else>{{decodeHTMLEntities(category.name)}}</span>
                </f7-button>
              </f7-col>
            </f7-row>
          </div>
          <div v-if="vendor.moods.length > 0" class="vendor-features-container">
            <h1 class="margin-0 web-width">{{localeTexts.moods_title}}</h1>
            <f7-row class="web-width">
              <f7-col
                v-for="mood in vendor.moods"
                :key="mood.term_id"
                :id="'choice-col-' + mood.term_id"
              >
                <f7-button
                  :id="'choice-' + mood.term_id"
                  class="select-btn selected"
                  outline
                  round
                  small
                >
                  <span v-if="mood.emoji">{{decodeHTMLEntities(mood.emoji + '&nbsp;&nbsp;' + mood.name)}}</span>
                  <span v-else>{{decodeHTMLEntities(mood.name)}}</span>
                </f7-button>
              </f7-col>
            </f7-row>
          </div>
          <div v-if="vendor.features.length > 0" class="vendor-features-container">
            <h1 class="margin-0 web-width">{{localeTexts.features_title}}</h1>
            <f7-row class="web-width">
              <f7-col
                v-for="feature in vendor.features"
                :key="feature.term_id"
                :id="'choice-col-' + feature.term_id"
              >
                <f7-button
                  :id="'choice-' + feature.term_id"
                  class="select-btn selected"
                  outline
                  round
                  small
                >
                  {{decodeHTMLEntities(feature.name)}}
                </f7-button>
              </f7-col>
            </f7-row>
          </div>
          <hr v-if="vendor.features.length > 0">
          <div v-if="vendor.reward_program" class="vendor-loyalty-container web-width">
            <h1 class="margin-0">{{localeTexts.loyalty_card_title}}</h1>
            <div>
              <f7-row class="loyalty-row-1" v-if="rewardProgram1.active !== false">
                <f7-col width="10"><img height="25" src="https://purplepages.app/wp-content/uploads/2023/11/loyalty_1.svg"></f7-col>
                <f7-col width="90">
                  <f7-row class="reward-header-row">
                    <f7-col v-if="rewardProgram1.type == 'freebie'">
                      <p>{{localeTexts.reward_free}} {{rewardProgram1.freebie_reward}}{{localeTexts.reward_with}}{{rewardProgram1.freebie_points}} {{localeTexts.reward_points}}</p>
                    </f7-col>
                    <f7-col v-if="rewardProgram1.type == 'discount'">
                      <p v-if="lang == 'en'">{{localeTexts.reward_discount}} {{rewardProgram1.discount_reward}} %{{localeTexts.reward_with}}{{rewardProgram1.discount_points}} {{localeTexts.reward_points}}</p>
                      <p v-else-if="lang == 'fi'">{{rewardProgram1.discount_reward}} % {{localeTexts.reward_discount}} {{rewardProgram1.discount_points}} {{localeTexts.reward_points}}</p>
                    </f7-col>
                  </f7-row>
                  <f7-row class="reward-desc-row">
                    <f7-col>
                      <p v-if="rewardProgram1.type == 'freebie'">{{rewardProgram1.freebie_description}}</p>
                      <p v-if="rewardProgram1.type == 'discount'">{{rewardProgram1.discount_description}}</p>
                    </f7-col>
                  </f7-row>
                </f7-col>
              </f7-row>
              <f7-row v-if="rewardProgram2.active !== false" class="loyalty-row-2">
                <f7-col width="10"><img width="25" src="https://purplepages.app/wp-content/uploads/2023/11/loyalty_2.svg"></f7-col>
                <f7-col width="90">
                  <f7-row class="reward-header-row">
                    <f7-col>
                      <p>{{localeTexts.reward_reach}} {{rewardProgram2.goal_amount}} € {{localeTexts.reward_to_get}} {{rewardProgram2.reward_amount}} € {{localeTexts.reward_reward}}</p>
                    </f7-col>
                  </f7-row>
                  <f7-row class="reward-desc-row">
                    <f7-col><p>{{rewardProgram2.description}}</p></f7-col>
                  </f7-row>
                </f7-col>
              </f7-row>
            </div>
          </div>
          <hr v-if="vendor.reward_program">
          <f7-list no-hairlines accordion-list class="vendor-reviews-container margin-0 web-width">
            <f7-list-item accordion-item class="accordion-item-opened">
              <div slot="title"><h1>{{localeTexts.reviews_title}} ({{reviewCount}})</h1></div>
              <f7-accordion-content>
                <div v-if="googleReviews.length > 0" class="list no-hairlines media-list margin-0">
                  <ul>
                    <!-- Google reviews -->
                    <li v-for="(review, index) in googleReviews" :key="index">
                      <div class="item-content">
                        <div
                          class="item-media"
                        >
                          <svg
                            width="33"
                            height="33"
                            style="
                              border-radius: 25px;
                              background-color: var(--light-purple);
                              padding: 2px;
                            "
                          >
                            <image
                              xlink:href="https://purplepages.app/wp-content/uploads/2023/05/face_FILL0_wght400_GRAD0_opsz48.svg"
                              style="filter: invert(100%); transform: scale(0.7)"
                            />
                          </svg>
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">{{review.userName}}</div>
                            <div class="item-after">{{review.timeAgo}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="review-message">{{review.message}}</div>
                      <hr>
                    </li>
                  </ul>
                </div>
                <div v-if="vendor.hasOwnProperty('review_data') && Object.keys(vendor.review_data.reviews).length > 0" class="list no-hairlines media-list margin-0">
                  <ul>
                    <!-- Review -->
                    <li v-for="review in vendor.review_data.reviews" :key="review.id">
                      <div class="item-content">
                        <div
                          v-if="!userData(review.userId).userPicture"
                          class="item-media"
                        >
                          <svg
                            width="33"
                            height="33"
                            style="
                              border-radius: 25px;
                              background-color: var(--light-purple);
                              padding: 2px;
                            "
                          >
                            <image
                              xlink:href="https://purplepages.app/wp-content/uploads/2023/05/face_FILL0_wght400_GRAD0_opsz48.svg"
                              style="filter: invert(100%); transform: scale(0.7)"
                            />
                          </svg>
                        </div>
                        <div v-else class="item-media">
                          <img
                            :src="userData(review.userId).userPicture"
                            width="33"
                            height="33"
                            style="border-radius: 25px"
                          >
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">{{userData(review.userId).userName}}</div>
                            <div v-if="lang == 'en'" class="item-after">{{review.timeAgoEn}}</div>
                            <div v-else-if="lang == 'fi'" class="item-after">{{review.timeAgoFi}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="review-message">{{review.message}}</div>
                      <!-- Response -->
                      <ul v-if="review.response !== false">
                        <li>
                          <div class="item-content">
                            <div
                              v-if="!userData(review.response.userId).userPicture"
                              class="item-media"
                            >
                              <svg
                                width="33"
                                height="33"
                                style="
                                  border-radius: 25px;
                                  background-color: var(--light-purple);
                                  padding: 2px;
                                "
                              >
                                <image
                                  xlink:href="https://purplepages.app/wp-content/uploads/2023/05/face_FILL0_wght400_GRAD0_opsz48.svg"
                                  style="filter: invert(100%); transform: scale(0.7)"
                                />
                              </svg>
                            </div>
                            <div v-else class="item-media">
                              <img
                                :src="userData(review.response.userId).userPicture"
                                width="33"
                                height="33"
                                style="border-radius: 25px"
                              >
                            </div>
                            <div class="item-inner">
                              <div class="item-title-row">
                                <div class="item-title">{{userData(review.response.userId).vendorName}}</div>
                                <div v-if="lang == 'en'" class="item-after">{{review.response.timeAgoEn}}</div>
                                <div v-else-if="lang == 'fi'" class="item-after">{{review.response.timeAgoFi}}</div>
                              </div>
                            </div>
                          </div>
                          <div class="review-message">{{review.response.message}}</div>
                        </li>
                      </ul>
                      <hr>
                    </li>
                  </ul>
                </div>
                <!-- Send review -->
                <div v-if="!incognitoUser" class="list no-hairlines media-list margin-0" :class="{ 'not-claimed': (!currentUser || (currentUser && currentUser.data.roles[0] != 'vendoruser')) && !vendorClaimed && !isEvent }">
                  <ul>
                    <li>
                      <div class="item-content item-input-outline">
                        <div
                          v-if="!user.profile_picture"
                          class="item-media"
                        >
                          <svg
                            width="33"
                            height="33"
                            style="
                              border-radius: 25px;
                              background-color: var(--light-purple);
                              padding: 2px;
                            "
                          >
                            <image
                              xlink:href="https://purplepages.app/wp-content/uploads/2023/05/face_FILL0_wght400_GRAD0_opsz48.svg"
                              style="filter: invert(100%); transform: scale(0.7)"
                            />
                          </svg>
                        </div>
                        <div v-else class="item-media">
                          <img
                            :src="user.profile_picture"
                            width="33"
                            height="33"
                            style="border-radius: 25px"
                          >
                        </div>
                        <div class="item-inner">
                          <div class="item-title">{{user.user_login}}</div>
                        </div>
                      </div>
                      <f7-list-input
                        outline
                        class="review-input"
                        type="textarea"
                        :placeholder="localeTexts.review_input_placeholder"
                        :value="replyMessage"
                        @input="replyMessage = $event.target.value"
                      />
                      <f7-button :disabled="replyMessage.length == 0" fill class="reply-btn" @click="sendReview()">{{localeTexts.post_review_btn}}</f7-button>
                    </li>
                  </ul>
                </div>
              </f7-accordion-content>
            </f7-list-item>
          </f7-list>
          <div v-if="(!currentUser || (currentUser && currentUser.data.roles[0] != 'vendoruser')) && !vendorClaimed && !isEvent" class="pseudo-sheet">
            <div>
              <p>{{localeTexts.claim_title}}</p>
              <f7-button
                class="claim-btn"
                outline
                sheet-open=".claim-vendor-sheet"
                @click="expandOrCollapseSheet()">{{localeTexts.claim_btn_1}}</f7-button>
            </div>
          </div>
        </div>
      </f7-sheet>

      <f7-sheet
        class="claim-vendor-sheet"
        :opened="sheetOpened"
        @sheet:closed="closeClaimSheet()"
        :backdrop="false"
        close-by-backdrop-click
        close-by-outside-click
        close-on-escape
        swipe-to-close
        style="height: auto;">
        <f7-toolbar>
          <div class="left" />
          <div class="right">
            <f7-link sheet-close icon-f7="xmark" @click="openSheet()" />
          </div>
        </f7-toolbar>
        <f7-page-content>
          <div style="padding-bottom: 20px;">
            <div class="leftright-margin">
              <h2>{{localeTexts.claim_text_1}}</h2>
              <h2>{{localeTexts.claim_text_2}}</h2>
            </div>
            <f7-button
              sheet-close
              class="purple-btn"
              @click="navigate('/claim-business/', {vendor_id: vendorId, phone: vendorPhone, is_restaurant: isRestaurant})"
            >{{localeTexts.claim_btn_2}}</f7-button>
          </div>
        </f7-page-content>
      </f7-sheet>

      <dialog id="opening-popup" style="display: none">
        <div id="opening-popup-content">
          <h1>{{localeTexts.opening_dialog_title}}</h1>
          <f7-row v-for="(day, index) in days" :key="index">
            <f7-col class="dayname-col">
              <h2 v-if="lang == 'en'">{{day.day_name_en}}</h2>
              <h2 v-else-if="lang == 'fi'">{{day.day_name_fi}}</h2>
            </f7-col>
            <f7-col class="hours-col">
              <h2>{{getOpeningHours(day.day_number)}}</h2>
            </f7-col>
          </f7-row>
          <f7-button class="purple-btn" @click="hideDialog('opening-popup')">{{localeTexts.close_btn}}</f7-button>
        </div>
      </dialog>

      <dialog id="custom-status-popup" style="display: none">
        <div id="custom-status-popup-content">
          <h1>{{currentStatus.label}}</h1>
          <h2>{{vendor.status_text}}</h2>
          <f7-button class="purple-btn" @click="hideDialog('custom-status-popup')">{{localeTexts.close_btn}}</f7-button>
        </div>
      </dialog>
    </template>
  </f7-page>
</template>

<script>
import * as util from "@/util";
import axios from "axios";
import {store} from "@/store/store.js";
import {mapState} from "vuex";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      sheetDestroyed: false,
      lang: "en",
      resetvendor: true,
      userPosition: {},
      vendorDistance: "",
      vendorId: null,
      vendorName: "",
      contentId: null,
      notFeed: true,
      placeId: null,
      isEvent: false,
      isRestaurant: false,
      rating: 0,
      currentUser: window.SAPlatform.user.current(),
      userFollowed: false,
      sheet: {},
      sheetExpanded: false,
      sheetOpened: false,
      showOrderButtons: false,
      replyMessage: "",
      profileViewed: false,
      hasMap: false,
      days: [
        {
          day_name_en: "Monday",
          day_name_fi: "Maanantai",
          day_number: "1",
        },
        {
          day_name_en: "Tuesday",
          day_name_fi: "Tiistai",
          day_number: "2",
        },
        {
          day_name_en: "Wednesday",
          day_name_fi: "Keskiviikko",
          day_number: "3",
        },
        {
          day_name_en: "Thursday",
          day_name_fi: "Torstai",
          day_number: "4",
        },
        {
          day_name_en: "Friday",
          day_name_fi: "Perjantai",
          day_number: "5",
        },
        {
          day_name_en: "Saturday",
          day_name_fi: "Lauantai",
          day_number: "6",
        },
        {
          day_name_en: "Sunday",
          day_name_fi: "Sunnuntai",
          day_number: "0",
        },
      ],
      googleReviews: [],
      texts: {
        en: {
          see_all_1: "See all",
          see_all_2: "photos",
          view_profile: "View profile",
          sold_out: "Sold out",
          away_text: "away",
          follow_profile_btn: "Follow profile",
          unfollow_profile_btn: "Unfollow profile",
          close_btn: "Close",
          cancel_btn: "Cancel",
          post_review_btn: "Post review",
          claim_btn_1: "Claim now!",
          claim_btn_2: "Claim this business",
          opening_dialog_title: "Opening hours",
          opening_closed: "Closed",
          opening_closes_at: "Closes at",
          opening_open: "Open",
          opening_opens_at: "Opens at",
          rating_text: "out of 5",
          person_recommended: "person recommended",
          people_recommended: "people recommended",
          person_viewed: "person viewed",
          people_viewed: "people viewed",
          follower: "follower",
          followers: "followers",
          buy_tickets: "Buy the tickets",
          make_reservation: "Make a reservation",
          restaurant_cats_title: "Restaurant categories",
          moods_title: "Moods",
          features_title: "Features",
          loyalty_card_title: "Loyalty card",
          reward_free: "Free",
          reward_with: " with ",
          reward_points: "points",
          reward_discount: "Discount",
          reward_reach: "Reach",
          reward_to_get: "to get",
          reward_reward: "reward",
          reviews_title: "Reviews",
          review_input_placeholder: "Write",
          claim_title: "Do you own this business?",
          claim_text_1: "This business has not been claimed by an owner or representative.",
          claim_text_2: "Claim your business today and unlock a loyalty card system, business analytics, deal and discount posting, and a free weblink editing tool. Unlock now to get your free access.",
          select_map_action_title: "Select the map application to use",
          anonymous_user: "Google user",
        },
        fi: {
          see_all_1: "Näytä kaikki",
          see_all_2: "kuvaa",
          view_profile: "Näytä profiili",
          sold_out: "Loppuunmyyty",
          away_text: "päässä",
          follow_profile_btn: "Seuraa",
          unfollow_profile_btn: "Älä seuraa",
          close_btn: "Sulje",
          cancel_btn: "Peruuta",
          post_review_btn: "Julkaise arvostelu",
          claim_btn_1: "Lunasta tästä!",
          claim_btn_2: "Lunasta tämä yritys",
          opening_dialog_title: "Aukioloajat",
          opening_closed: "Suljettu",
          opening_closes_at: "Sulkeutuu",
          opening_open: "Avoinna",
          opening_opens_at: "Avautuu",
          rating_text: "/ 5",
          person_recommended: "henkilö suosittelee",
          people_recommended: "henkilöä suosittelee",
          person_viewed: "henkilö katsoi",
          people_viewed: "henkilöä katsoi",
          follower: "seuraaja",
          followers: "seuraajaa",
          buy_tickets: "Osta liput",
          make_reservation: "Tee varaus",
          restaurant_cats_title: "Ravintolakategoriat",
          moods_title: "Tunnelmat",
          features_title: "Ominaisuudet",
          loyalty_card_title: "Etukortti",
          reward_free: "Ilmainen",
          reward_with: " ",
          reward_points: "pisteellä",
          reward_discount: "alennus",
          reward_reach: "Saavuta",
          reward_to_get: "saadaksesi",
          reward_reward: "palkinnon",
          reviews_title: "Arvostelut",
          review_input_placeholder: "Kirjoita",
          claim_title: "Omistatko tämän yrityksen?",
          claim_text_1: "Omistaja tai edustaja ei ole lunastanut tätä yritystä.",
          claim_text_2: "Lunasta yrityksesi jo tänään ja ota käyttöön kanta-asiakaskorttijärjestelmä, yritysanalytiikka, tarjousten ja alennusten lähettäminen sekä ilmainen verkkolinkkien muokkaustyökalu. Avaa lukitus nyt saadaksesi ilmaiseksi käyttöösi.",
          select_map_action_title: "Valitse haluamasi karttasovellus",
          anonymous_user: "Google käyttäjä",
        },
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.app.user,
      vendor: (state) => state.app.vendor_profile,
      reviews: (state) => state.app.vendor_profile.review_data,
      filterChoices: (state) => state.app.filter_choices,
    }),
    isMobileBrowser() {
      return !this.$device.cordova && !this.$device.desktop;
    },
    openingInfo() {
      const result = {status: "closed", hours: this.localeTexts.opening_closed};
      let status = this.vendor.status;
      if (status) {
        status = status.value.toLowerCase();
        if (status.includes("closed")) {
          return result;
        }
      }

      const openingHours = this.vendor.opening_hours;
      if (!openingHours) return result;

      const today = new Date().getDay()
        .toString();
      const index = openingHours.findIndex((day) => day.day === today);
      if (index === -1) {
        const sundayIndex = openingHours.findIndex((day) => day.day === "0");
        if (sundayIndex !== -1) {
          const sundayHours = openingHours[sundayIndex];
          const openSunday = sundayHours.open;
          const closeSunday = sundayHours.close;

          if (openSunday == "00:00" && closeSunday == "") {
            result.status = "open";
            result.hours = `${this.localeTexts.opening_open} 24/7`;
            return result;
          }
          return result;
        }
        return result;
      }

      let todayHours = openingHours[index];
      const now = new Date();
      const open = new Date();
      const close = new Date();

      const openHours = parseInt(todayHours.open.split(":")[0]);
      const closeHours = parseInt(todayHours.close.split(":")[0]);
      if (openHours > closeHours) {
        close.setDate(close.getDate() + 1);
      }

      open.setHours(todayHours.open.split(":")[0]);
      open.setMinutes(todayHours.open.split(":")[1]);
      close.setHours(todayHours.close.split(":")[0]);
      close.setMinutes(todayHours.close.split(":")[1]);
      if (now > open && now < close) {
        todayHours = `${this.localeTexts.opening_closes_at} ${todayHours.close}`;
        result.status = "open";
      } else if (now < open) {
        todayHours = `${this.localeTexts.opening_opens_at} ${todayHours.open}`;
      } else {
        todayHours = this.localeTexts.opening_closed;
      }

      result.hours = todayHours;

      return result;
    },
    currentStatus() {
      const status = this.vendor.status;
      const data = {
        label: "Undefined",
        status: "undefined",
      };
      if (status) {
        if (!status.value.includes("closed")) {
          data.status = "open";
        } else {
          data.status = "closed";
        }
        data.label = status.label;
        return data;
      }
      return data;
    },
    visibleOrderButtons() {
      if (this.$data.showOrderButtons) {
        return "visible";
      }
      return "hidden";
    },
    rewardProgram1() {
      return this.vendor.rewards.reward_1;
    },
    rewardProgram2() {
      return this.vendor.rewards.reward_2;
    },
    userActivity() {
      const activity = this.vendor.user_activity;
      if (activity) {
        return activity;
      }
      return {
        follow: false,
        like: false,
        view: false,
      };
    },
    vendorAddress() {
      if (this.vendor.location) {
        let street = this.vendor.location.street;
        let postcode = this.vendor.location.postcode;
        let city = this.vendor.location.city;

        if (street) street = `${street}, `;
        else street = "";

        if (postcode) postcode = `${postcode} `;
        else postcode = "";

        if (!city) city = "";

        return street + postcode + city;
      }
      return "";
    },
    eventContent() {
      if (this.$data.isEvent) {
        const contents = this.vendor.content;
        if (contents) {
          const index = contents.findIndex((content) => content.type_slug === "event");
          if (index !== -1) {
            const content = contents[index];
            if (content.event_start) {
              const date = new Date(content.event_start);
              content.event_start_formated = date.toLocaleString([], {dateStyle: "short", timeStyle: "short"});
            }
            return content;
          }
          return false;
        }
        return false;
      }
      return false;
    },
    localeTexts() {
      return this.$data.texts[this.$data.lang];
    },
    incognitoUser() {
      if (this.currentUser) {
        const userRole = this.$data.currentUser.data.roles[0];
        if (userRole == "vendoruser") {
          return true;
        }
        return false;
      }
      return true;
    },
    vendorPhone() {
      if (this.vendor.contact) {
        return this.vendor.contact.phone;
      }
      return "";
    },
    vendorClaimed() {
      if (this.vendor.user) {
        return true;
      }
      return false;
    },
    reviewCount() {
      let count = 0;
      if (this.googleReviews.length > 0) count += this.googleReviews.length;
      if (this.vendor.hasOwnProperty("review_data") && Object.keys(this.vendor.review_data.reviews).length > 0) count += Object.keys(this.vendor.review_data.reviews).length;
      return count;
    },
  },
  watch: {
    vendor: {
      handler(newValue, oldValue) {
        if (newValue && newValue.hasOwnProperty("user_activity")) {
          this.$data.userFollowed = newValue.user_activity.follow;
        }
      },
      deep: true,
    },
  },
  beforeDestroy() {
    if (this.$refs.sheet) this.$refs.sheet.f7Sheet.destroy();
    this.sheetDestroyed = true;

    const el = this.$$(".vendor-sheet");
    if (el) el.remove();
  },
  beforeCreate() {
    this.$store.commit("initialiseStore");
  },
  async created() {
    this.profileViewed = false;

    if (window.SAPlatform.language.current) this.$data.lang = window.SAPlatform.language.current;

    if (this.$f7route.params?.slug && this.$f7route.params.slug != this.vendor.vendor_name) {
      await this.$store.dispatch("app/getVendorProfileFromSlug", this.$f7route.params.slug);
    } else {
      await util.wait(5);
      await this.$nextTick();
      // Try to decode payload
      let payload = this.$f7router.url.split("pl--")[1];

      if (payload) {
        if (payload.endsWith("/")) payload = payload.slice(0, -1);
        const payloadData = JSON.parse(atob(payload));
        const data = payloadData?.data?.[0];

        const vendorId = data?.vendor_id;
        const contentId = data?.content_id;
        this.contentId = contentId;

        if (!data.not_feed) {
          this.notFeed = data.not_feed;
        }

        if (vendorId && vendorId !== this.vendor.vendor_id) {
          await this.$store.dispatch("app/getVendorProfile", vendorId);
        }
      }
    }

    this.vendorId = this.vendor.vendor_id;

    if (this.vendor.company.public_name != "PurplePages") {
      document.title = `${this.vendor.company.public_name} | PurplePages`;
    }

    if (this.$f7route.query?.not_feed == 1) {
      this.notFeed = true;
    }

    if (this.$f7route.query?.ch) {
      this.contentId = util.decodeContentId(this.$f7route.query.ch, this.vendorId);
    }

    this.userFollowed = this.vendor.user_activity?.follow || false;

    this.$data.vendorName = this.vendor.company.public_name;
    this.$data.placeId = this.vendor.place_id;
    if (this.vendor.vendor_types.includes("events")) this.$data.isEvent = true;
    if (this.vendor.vendor_types.includes("restaurant")) this.$data.isRestaurant = true;

    await util.getGeolocation().then((pos) => {
      this.$data.userPosition = {
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
      };
      this.checkDistance();
    }, (err) => {
      console.log(err);
    });

    if (this.$data.placeId) {
      const request = {
        placeId: this.$data.placeId,
        fields: ["rating", "reviews"],
        reviews_sort: "newest",
      };

      await util.getPlaceDetails(request).then(response => {
        this.$data.rating = response.rating;
        if (response.reviews) this.handleReviews(response.reviews);
      })
        .catch(error => {
          console.log(error);
        });
    }

    const prevRoute = this.$f7.views.main.router.previousRoute;
    if (prevRoute && prevRoute.path && prevRoute.path.includes("/vendor-feed/")) {
      this.$data.resetvendor = false;
    }

    this.$store.dispatch("analytics/trackLookingFor", [this.vendorId, this.filterChoices]);
  },
  async mounted() {
    await this.$nextTick();

    if (this.isMobileBrowser) {
      this.$$(".vendor-sheet").css("height", "80vh");
      this.$$(".vendor-reviews-container").css("padding-bottom", "15vh");

      if (!this.vendorClaimed) {
        this.$$(".vendor-reviews-container").css("padding-bottom", "21vh");
      }
    } else if (!this.vendorClaimed) {
      this.$$(".vendor-reviews-container").css("padding-bottom", "calc(env(safe-area-inset-bottom) + 11vh)");
    }

    await util.wait(500);

    this.$$(".sheet-modal-swipe-step").trigger("click");
  },
  methods: {
    backHandler() {
      const router = this.$f7router;
      if (router.history.length === 1) {
        this.$f7router.navigate("/", {reloadCurrent: true});
      }
    },
    onSheetProgress(sheet, progress) {
      if (progress == 1) this.sheetExpanded = true;
      else if (progress == 0) this.sheetExpanded = false;
    },
    decodeHTMLEntities(text) {
      const textArea = document.createElement("textarea");
      textArea.innerHTML = text;
      return textArea.value;
    },
    async openSheet() {
      for (let i = 0; i < 10; i++) {
        await util.wait(50);
        if (this.$refs.sheet) break;
      }

      if (!this.$refs.sheet) {
        console.warn("Unable to open sheet, element does not exist");
        return;
      }

      this.$refs.sheet.open();

      if (this.isMobileBrowser) {
        this.$$(".vendor-post-slides").css("height", `calc(100% - ${$(".sheet-modal-swipe-step").height()}px + 1vh)`);
        this.$$(".vendor-post-slide.swiper-slide").css("height", "100%");
      } else {
        this.$$(".vendor-post-slides").css("height", `calc(101vh - ${$(".sheet-modal-swipe-step").height()}px)`);
        this.$$(".vendor-post-slide.swiper-slide").css("height", `calc(101vh - ${$(".vendor-sheet .sheet-modal-swipe-step").height()}px)`);
      }
    },
    closeSheet() {
      if (this.$refs.sheet) {
        this.$refs.sheet.close();
      }

      const event = new CustomEvent("force-mute");
      window.dispatchEvent(event);
    },
    resetVendor() {
      if (this.$data.resetvendor) this.$store.dispatch("app/resetVendorProfile");
    },
    async expandOrCollapseSheet() {
      this.$refs.sheet.f7Sheet.stepToggle();
      this.$data.sheetExpanded = !this.$data.sheetExpanded;
    },
    closeClaimSheet() {
      this.$data.sheetOpened = false;
    },
    contactLink(url) {
      window.location.href = url;

      const event = new CustomEvent("force-mute");
      window.dispatchEvent(event);
    },
    openSocial(service) {
      if (service == "facebook") {
        this.openLink(`https://facebook.com/${this.vendor.contact.fb_account}`);
      } else if (service == "instagram") {
        this.openLink(`https://www.instagram.com/${this.vendor.contact.ig_account}`);
      } else if (service == "tiktok") {
        this.openLink(`https://tiktok.com/@${this.vendor.contact.tiktok_account}`);
      }

      const event = new CustomEvent("force-mute");
      window.dispatchEvent(event);
    },
    openMap() {
      if (this.$device.ios) {
        const actionSheet = this.$f7.actions.create({
          buttons: [
            [
              {
                text: this.localeTexts.select_map_action_title,
                label: true,
                bold: true,
              },
              {
                text: "Google Maps",
                onClick: () => {
                  this.openGoogleMaps();
                },
              },
              {
                text: "Apple Maps",
                onClick: () => {
                  this.openAppleMaps();
                },
              },
            ],
            [
              {
                text: this.localeTexts.cancel_btn,
                color: "red",
              },
            ],
          ],
        });
        actionSheet.open();
      } else {
        this.openGoogleMaps();
      }
    },
    openGoogleMaps() {
      const url = `https://www.google.com/maps/search/?api=1&query=${this.vendor.location.latitude},${this.vendor.location.longitude}`;
      this.openLink(url);
    },
    openAppleMaps() {
      const url = `http://maps.apple.com/?q=${this.vendor.location.latitude},${this.vendor.location.longitude}`;
      this.openLink(url);
    },
    openLink(url) {
      if (window.cordova) {
        const iab = cordova.InAppBrowser;
        iab.open(url, "_system");
      } else {
        window.open(url, "_blank");
      }

      const event = new CustomEvent("force-mute");
      window.dispatchEvent(event);
    },
    navigate(path, payload = false) {
      this.$data.resetvendor = false;

      if (payload != false) {
        payload = this.createLinkWithPayload("", payload);
        path = path + payload.slice(2);
      }

      this.$f7router.navigate(path);

      const event = new CustomEvent("force-mute");
      window.dispatchEvent(event);
    },
    userData(userId) {
      const index = this.reviews.users.findIndex((user) => user.userId === userId);
      return this.reviews.users[index];
    },
    async sendReview() {
      this.$f7.preloader.show();
      await this.$store.dispatch("reviews/sendReview", {
        vendorId: this.$data.vendorId,
        userId: this.user.user_id,
        message: this.$data.replyMessage,
      });
      await this.$store.dispatch("app/getVendorProfile", this.$data.vendorId);
      this.$data.replyMessage = "";
      this.$f7.preloader.hide();
    },
    async updateFollow(endpoint) {
      if (endpoint == "add") {
        this.$data.userFollowed = true;
      } else {
        this.$data.userFollowed = false;
      }

      const payload = {
        type: "follow",
        subject: this.$data.vendorId,
      };
      await axios.post(`${window.SAPlatform.server.url}wp-json/superapp/v1/activity/${endpoint}`, payload).then((response) => {
        this.updateVendor();
      })
        .catch(error => {
          console.log(error);
        });
    },
    async updateVendor() {
      await this.$store.dispatch("app/getVendorProfile", this.$data.vendorId);
    },
    async checkDistance() {
      if (this.$data.userPosition.lat && this.$data.userPosition.lng) {
        if (this.vendor.hasOwnProperty("location") && this.vendor.location.hasOwnProperty("latitude") && this.vendor.location.hasOwnProperty("longitude")) {
          if (this.vendor.location.latitude != 0 && this.vendor.location.longitude != 0) {
            const userPosition = {lat: this.$data.userPosition.lat, lng: this.$data.userPosition.lng};
            const vendorPosition = {lat: this.vendor.location.latitude, lng: this.vendor.location.longitude};
            await util.getHaversineDistance(userPosition, vendorPosition).then((response) => {
              this.$data.vendorDistance = response;
            });
          }
        }
      }
    },
    getOpeningHours(day) {
      if (this.vendor && this.vendor.opening_hours && this.vendor.opening_hours.length > 0) {
        const index = this.vendor.opening_hours.findIndex((opening) => opening.day === day);
        if (index !== -1) {
          const openingHours = this.vendor.opening_hours[index];

          if (day == "0" && openingHours.open == "00:00" && openingHours.close == "") {
            return `${this.localeTexts.opening_open} 24/7`;
          }
          return `${openingHours.open} - ${openingHours.close}`;
        }
        const sundayIndex = this.vendor.opening_hours.findIndex((day) => day.day === "0");
        if (sundayIndex !== -1) {
          const sundayHours = this.vendor.opening_hours[sundayIndex];
          const openSunday = sundayHours.open;
          const closeSunday = sundayHours.close;

          if (openSunday == "00:00" && closeSunday == "") {
            return `${this.localeTexts.opening_open} 24/7`;
          }
          return this.localeTexts.opening_closed;
        }
        return this.localeTexts.opening_closed;
      }
      return this.localeTexts.opening_closed;
    },
    showDialog(id) {
      $(".vendor-sheet-content").css("overflow-y", "hidden");

      $(`#${id}`).css("display", "flex");
      const dialog = document.getElementById(id);
      dialog.showModal();
      dialog.addEventListener("click", () => {
        dialog.close();
        $(`#${id}`).css("display", "none");
      });

      const dialogContent = document.getElementById(`${id}-content`);
      dialogContent.addEventListener("click", (event) => event.stopPropagation());
    },
    hideDialog(id) {
      $(`#${id}`).css("display", "none");
      const dialog = document.getElementById(id);
      dialog.close();

      $(".vendor-sheet-content").css("overflow-y", "scroll");
    },
    handleReviews(reviews) {
      const reviewsArray = [];

      reviews.forEach((review) => {
        if (review.text && review.time) {
          const timeDiffString = this.getTimeDiffString(review.time);

          const reviewObject = {
            userName: this.localeTexts.anonymous_user,
            message: review.text,
            timeAgo: timeDiffString,
            time: review.time,
          };

          reviewsArray.push(reviewObject);
        }
      });

      reviewsArray.sort((a, b) => b.time - a.time);

      this.$data.googleReviews = reviewsArray;
    },
    getTimeDiffString(timestamp) {
      const datetime = new Date(timestamp * 1000);
      const now = new Date();
      const diffMilliseconds = now.getTime() - datetime.getTime();

      const diffYears = Math.floor(diffMilliseconds / (365.25 * 24 * 60 * 60 * 1000));
      const diffMonths = Math.floor(diffMilliseconds / (30.44 * 24 * 60 * 60 * 1000));
      const diffDays = Math.floor(diffMilliseconds / (24 * 60 * 60 * 1000));
      const diffHours = Math.floor(diffMilliseconds / (60 * 60 * 1000));
      const diffMinutes = Math.floor(diffMilliseconds / (60 * 1000));

      let diffString = "";

      if (this.$data.lang == "en") {
        diffString =
          diffYears ? `${diffYears} yr ago` :
            diffMonths ? `${diffMonths} mth ago` :
              diffDays ? `${diffDays} d ago` :
                diffHours ? `${diffHours} h ago` :
                  diffMinutes ? `${diffMinutes} min ago` :
                    "just now";
      } else if (this.$data.lang == "fi") {
        diffString =
          diffYears ? `${diffYears} v sitten` :
            diffMonths ? `${diffMonths} kk sitten` :
              diffDays ? `${diffDays} pv sitten` :
                diffHours ? `${diffHours} h sitten` :
                  diffMinutes ? `${diffMinutes} min sitten` :
                    "juuri nyt";
      }

      return diffString;
    },
  },
};
</script>

<style type="css/scss" scoped>
.page-template >>> .page-content {
  background: var(--template-body-background);
  background-size: 100% auto;
  padding-top: 0;
  padding-bottom: 0;
}

.vendor-logo {
  position: fixed;
  top: calc(var(--f7-safe-area-top) + 20px);
  right: 20px;
  z-index: 2;
}

.show-all-imgs {
  position: fixed;
  right: 20px;
  bottom: calc(90px + 3vh);
  z-index: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-shadow: 0px 2px 3px #000000;
}

.show-all-imgs span {
  font-size: 13px;
  font-weight: 600;
  color: white;
}

/*
** Sheet
*/

.vendor-sheet {
  height: 90vh;
}

.vendor-sheet .sheet-modal-swipe-step {
  cursor: pointer;
}

.vendor-sheet .sheet-modal-swipe-step hr {
  margin-bottom: 0;
  background-color: rgb(112, 112, 112, 0.15);
  color: rgb(112, 112, 112, 0.15);
}

.vendor-sheet .sheet-modal-swipe-step i.icon {
  padding-top: 5px;
  color: var(--purple);
}

.vendor-sheet .sheet-modal-swipe-step p {
  margin: 0 0 10px;
  font-size: 14px;
  font-weight: 600;
}

#view-profile-icon {
  position: relative;
  -webkit-animation: floatBubble 2s infinite ease-out;
  animation: floatBubble 2s infinite ease-out;
}

@-webkit-keyframes floatBubble {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
	40% {
    transform: translateY(-10px);
  }
	60% {
    transform: translateY(-5px);
  }
}

@keyframes floatBubble {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
	40% {
    transform: translateY(-10px);
  }
	60% {
    transform: translateY(-5px);
  }
}

/*
** Sheet content
*/

.vendor-sheet-content {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: 85vh;
}

.vendor-profile-container,
.vendor-bio-container,
.vendor-list-container,
.vendor-buttons-container,
.vendor-features-container,
.vendor-reviews-container,
.vendor-loyalty-container {
  padding-left: 20px;
  padding-right: 20px;
}

.vendor-reviews-container >>> .item-content,
.vendor-reviews-container .review-message {
  padding-left: 0;
  padding-right: 0;
}

.vendor-list-container >>> .list .item-content {
  padding-left: 0;
}

.vendor-reviews-container {
  padding-bottom: calc(env(safe-area-inset-bottom) + 3vh);
}

.vendor-profile-container {
  padding-top: 2vh;
  padding-bottom: 1vh;
}

.vendor-bio-container {
  padding-bottom: 2vh;
}

.vendor-loyalty-container {
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.vendor-list-container,
.vendor-buttons-container {
  padding-top: 0;
  padding-bottom: 2vh;
}

.vendor-features-container {
  padding-top: 2vh;
}

.vendor-loyalty-container {
  margin-top: -0.5em;
}

/*
** Profile container
*/

.vendor-profile-container .sold-out-badge {
  margin-left: 10px;
  background-color: var(--red);
}

.follow-btn,
.unfollow-btn {
  text-transform: none;
  font-size: 14px;
  height: 30px;
  width: fit-content;
  padding-left: 30px;
  line-height: 28px;
  text-align: right;
  border: 1px solid var(--purple);
}

.follow-btn {
  background-color: var(--purple);
  color: white;
}

.unfollow-btn {
  background-color: white;
  color: var(--purple);
}

.follow-btn >>> i.icon,
.unfollow-btn >>> i.icon {
  position: absolute;
  left: 7px;
  top: 4px;
  font-size: 20px;
}

/*
**  Opening popup
*/

.opening-col i {
  color: var(--purple);
  margin-right: 10px;
}

.underline-col h2 {
  text-decoration: underline;
  cursor: pointer;
}

#opening-popup,
#custom-status-popup {
  border-radius: 10px;
  background-color: white;
  box-sizing: border-box;
  border: 0;
  display: flex;
  padding: 4vh;
  width: auto;
  max-width: 315px;
}

#opening-popup::backdrop,
#custom-status-popup::backdrop {
  background-color: rgb(4, 7, 32, 0.63);
}

#opening-popup-content,
#custom-status-popup-content {
  width: 100%;
  text-align: center;
}

#opening-popup-content h1 {
  margin-top: 0;
  margin-bottom: 3vh;
}

#opening-popup-content h2,
#custom-status-popup-content h1,
#custom-status-popup-content h2 {
  margin-top: 0;
  margin-bottom: 2vh;
}

#opening-popup-content .dayname-col h2 {
  font-weight: 600;
  text-align: left;
}

#opening-popup-content .hours-col h2 {
  text-align: right;
}

#opening-popup-content .purple-btn {
  margin-top: 2vh;
}

#custom-status-popup-content .purple-btn {
  margin-top: 3vh;
}

/*
** Bio container
*/

.vendor-bio-container .vendor-bio {
  font-weight: 300;
  font-size: 16px;
  margin: 0;
  white-space: break-spaces;
}

/*
** List container
*/
/*
** Buttons container
*/
/*
** Features container
*/

.vendor-features-container h1 {
  padding-bottom: 2vh;
}

/*
** Loyalty container
*/

/*
** Reviews container
*/

.vendor-reviews-container .accordion-item >>> .item-inner:before {
  color: var(--purple);
  font-size: 35px;
}

.vendor-reviews-container .list ul ul {
  padding-left: 0;
}

.vendor-reviews-container .list .item-inner {
  padding-right: 0;
}

.vendor-reviews-container .list .item-inner:after {
  display: none;
}

.vendor-reviews-container .accordion-item-content .item-inner {
  align-self: center;
}

.vendor-reviews-container .accordion-item-content .item-title {
  font-size: 16px;
  font-weight: 600;
}

.vendor-reviews-container .accordion-item-content .item-after {
  color: var(--light-purple);
  font-size: 16px;
}

.vendor-reviews-container .item-media img {
  box-shadow: 0px 3px 6px #00000029;
  object-fit: cover;
}

.review-message {
  font-size: 15px;
  font-weight: 300;
  padding-bottom: 1vh;
}

.reply-btn {
  height: 30px;
  line-height: 30px;
  width: fit-content;
  min-width: 90px;
  background-color: var(--purple);
  text-transform: none;
  font-size: 14px;
  margin-left: auto;
  margin-right: 20px;
}

/*
** Pseudo sheet
*/

.pseudo-sheet {
  height: 50px;
  width: 100vw;
  display: block;
  position: fixed;
  bottom: 0;
  background-color: var(--purple);
  z-index: 10;
  padding-bottom: var(--f7-safe-area-bottom);
}

.pseudo-sheet div {
  width: 100%;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pseudo-sheet p {
  color: white;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.pseudo-sheet .claim-btn {
  border: 1px solid white;
  color: var(--orange);
  text-transform: none;
  font-size: 14px;
  white-space: break-spaces;
  margin-left: 2vw;
  width: fit-content;
  height: 35px;
  line-height: 33px;
}

.media-list.not-claimed {
  margin-bottom: 30px;
}

/*
** Web view
*/

@media only screen and (min-width: 600px) {
  .claim-vendor-sheet .centered-element {
    width: 50%;
    transform: translate(50%, -50%);
  }

  .show-all-imgs {
    right: 22vw;
  }

  .vendor-logo {
    right: 22vw;
  }
}
</style>
